/* global window */
import './sprite_carousel.scss';

var THE_SCRIPT = function () {
	const $onReady = require('public/js/lib/on-ready');

	$onReady({rp$, window,
		label: 'sprite_carousel',
		fn: function () {},
	});
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}
